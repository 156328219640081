import React from 'react';
import { BaseService } from '../services/base-service';
import './content.css';
import './emailcontactus-content.css';

export class GetInspiredContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            madeForSchoolsList: [
                { icon: 'made-for-school-icon1.png', titleElement: <>WE BRING<br />INDUSTRY TO YOU</> },
                { icon: 'made-for-school-icon2.png', titleElement: <>DELIVERS KNOWLEDGE,<br />HOPE & OPPORTUNITIES</> },
                { icon: 'made-for-school-icon3.png', titleElement: <>INTERACTIVE<br />EVENTS</> },
                { icon: 'made-for-school-icon4.png', titleElement: <>IT'S FREE</> },
                { icon: 'made-for-school-icon5.png', titleElement: <>BREAKS<br />ECONOMIC<br />BARRIERS</> },
                { icon: 'made-for-school-icon6.png', titleElement: <>BREAKS<br />GEOGRAPHIC<br />BARRIERS</> },
                { icon: 'made-for-school-icon7.png', titleElement: <>CORRELATES<br />STUDIES TO<br />OPPORTUNITIES</> },
                { icon: 'made-for-school-icon8.png', titleElement: <>IT'S SAFE</> }
            ],
            isOnVideoPlayMode: false
        }
    }

    componentDidMount() {
    }

    handleOnClickPlayVideo = () => {
        const WatchTiggbeeInAction = document.getElementById('WatchTiggbeeInAction');
        if(WatchTiggbeeInAction) {
            this.setState(state => {
                const newState = {
                    isOnVideoPlayMode: true
                }
                return newState;
            }, () => {
                if(WatchTiggbeeInAction.play)
                    WatchTiggbeeInAction.play();
            })
        }
    }

    renderMailContent = () => {
        const content = <>
            <div className="container">
                <div className="row email-contact-us-content-first-row-div">
                    <div className='email-contact-us-content-first-row-background-div' ></div>
                    <div className="col-12 inspiration-blankspace"> </div>
                    <div className="col-12 col-md-7" style={{justifyContent: 'flex-start'}}>
                        
                        <div className='video-player-wrapper-div'>
                            <video controls id='WatchTiggbeeInAction' >
                                <source src={BaseService.getVideoURL('/public/public-files/Tiggbee+In+Action.mp4')}
                                type="video/mp4" />
                            </video>
                            {
                                (!this.state.isOnVideoPlayMode)?(
                                    <>
                                        <div onClick={this.handleOnClickPlayVideo} className='video-player-poster-div' style={{backgroundImage: 'URL(/email-contact-us-video-background.png)'}}>
                                            <div className='video-player-poster-title-div'>Watch Tiggbee In Action</div>
                                        </div>
                                        <img onClick={this.handleOnClickPlayVideo} className='video-player-play-img' src='/email-contact-us-video-play.svg' alt='' />
                                    </>
                                ):(
                                    <></>
                                )
                            }
                        </div>
                        <br />
                    </div>
                    <div className="col-12 col-md-5" style={{  padding: 0, justifyContent: 'flex-start'}}>
                        <div className='homepage-content-technology-title' style={{fontSize: '30px', fontWeight: '600', marginTop: '28px', marginBottom: '30px'}}>Career Exploration & Inspiration</div>
                        <div className='homepage-content-technology-description schoolpage-content-technology-description' style={{fontSize: '18px'}}>
                            With Tiggbee, students engage with industry experts and learn how their studies can lead to future careers.
                        </div>
                        <a href="https://event.tiggbee.com/authentication/createaccount" className="create-account-button">Create Account</a>
                    </div>
                </div>
                <div className='row email-contact-us-content-info-row'>
                    <div className='features-background-div'></div>
                    <div className="col-12">
                        <div className='email-contact-us-content-info-wrapper-div'>
                            <div>
                                <img className='email-contact-us-content-info-img' src='/business-page-leader-paragraph-options-icon.png' alt='' />
                                <b>Weekly career events</b> – short, professional video introductions featuring local, regional, and national industry experts.
                            </div>
                        </div>
                        <div className='email-contact-us-content-info-wrapper-div'>
                            <div>
                                <img className='email-contact-us-content-info-img' src='/business-page-leader-paragraph-options-icon.png' alt='' />
                                <b>Successful role models</b> – successful business owners and operators who are engaging and who have a vested interest in the success of the next generation of workers.
                            </div>
                        </div>
                        <div className='email-contact-us-content-info-wrapper-div'>
                            <div>
                                <img className='email-contact-us-content-info-img' src='/business-page-leader-paragraph-options-icon.png' alt='' />
                                <b>Engaging programming</b> – questions from students are answered by the presenter with a video in the following session.
                            </div>
                        </div>
                        <div className='email-contact-us-content-info-wrapper-div'>
                            <div>
                                <img className='email-contact-us-content-info-img' src='/business-page-leader-paragraph-options-icon.png' alt='' />
                                <b>Convenient </b> – Tiggbee easily fits into the school day, is free – and advertising free.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>;
        return content;
    }
    
    renderMadeForSchoolsPart = () => {
        const content = <>
            <div className='schoolpage-content-made-for-schools-container'>
                <div className='schoolpage-content-made-for-schools-title'>Made for Schools</div>
                <div className='row'>
                    {
                        this.state.madeForSchoolsList.map((item, index) => 
                            <div className='col-6 col-md-3' key={index}>{ this.renderMadeForSchoolItemContent(item.icon, item.titleElement) }</div>
                        )
                    }
                </div>
            </div>
        </>;
        return content;
    }

    renderMadeForSchoolItemContent = (icon, titleElement) => {
        const content = <>
            <div className='schoolpage-content-made-for-schools-item'>
                <img alt=''
                    src={`/${icon}`}
                />
                <div>{titleElement}</div>
            </div>
        </>;
        return content;
    }

    renderIndustriesList = () => {
        const content = 
            <div className='studentpage-quote-container'>
                <div className='schoolpage-content-industries-container'>
                    <div className='business-logo business-logo1'>
                        <div></div><div></div>
                    </div>
                    <div className='business-logo business-logo2'>
                        <div></div><div></div>
                    </div>
                    <div className='schoolpage-content-industrie-item schoolpage-content-industrie-item3'>Business, Finance and Professional Services</div>
                    <div className='schoolpage-content-industrie-item schoolpage-content-industrie-item2'>Education, Child Development and Family Services</div>
                    <div className='schoolpage-content-industrie-item schoolpage-content-industrie-item1'>Energy, Environment and Utilities</div>
                    <div className='schoolpage-content-industrie-item schoolpage-content-industrie-item2'>Engineering and Architecture</div>
                    <div className='schoolpage-content-industrie-item schoolpage-content-industrie-item3'>
                        Fashion and Interior Design
                        <div style={{height:0,width:'100%'}}>
                            <div className='industry-item-overlay'>
                                </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        return content;
    }

    render() {
        const html = <>
            <div className='email-contact-us-content-wrapper-div'>
                { this.renderMailContent() }
                { this.renderMadeForSchoolsPart() }
                { 
                // this.renderIndustriesList() 
                }
            </div>
        </>;
        return (
            html
        )
    }
}